export enum WorkShiftCheckFlags {
  /** V průběhu výpočtu došlo k výjimce, chyba je nejspíše v datech nebo v logice. */
  EXCEPTION = 'X00',

  /** Není žádná smlouva na nabídce. */
  NO_CONTRACTS = 'C00',

  /** Podepsaná smlouva neodpovídá požadované smlouvě dle nabídky. */
  CONTRACTS_MISMATCH = 'C01',
  /** Více nekompatibilních smluv ve stejný moment, buď již podepsaných, nebo navržené nové smlouvy s podepsanými. */
  CONTRACTS_OVERLAP = 'C02',
  /** Existovaly by směny na DPP později než směny na DPČ nebo HPP. */
  CONTRACTS_DOWNGRADE = 'C03',
  /** Šablona smlouvy nebyla schválena k opakovanému použití. */
  CONTRACT_RENEWAL_DISALLOWED = 'C05',
  /** Tento typ smlouvy není povolen. */
  CONTRACT_NOT_ALLOWED = 'C06',
  /** Byla vyžádaná konkrétní podepsaná smlouva, ale nebyla nalezena nebo neodpovídá požadavkům. */
  CONTRACT_NOT_FOUND = 'C07',
  /** Nově navržená smlouva by nedodržela povinný rozestup, nejčastěji protože směna je přesně den po předchozí DPČ. */
  CONTRACTS_BREAK_OVERLAP = 'C08',

  /** Bylo zváženo více smluv, ale žádná neprošla. */
  MULTIPLE_DECLINES = 'D01',

  /** Nabídnout kontaktování podpory, aby tymberovi bylo zapnuto HPP. */
  OFFER_HPP = 'O01',
  /** Nabídnout vypnutí funkce hlídání překročení rozhodného příjmu. */
  OFFER_SALARY_UNLIMIT = 'O02',

  /** Směna překračuje povolenou délku. */
  SHIFT_TOO_LONG = 'S01',
  /** Je přihlášená překrývající se směna. */
  SHIFTS_OVERLAP = 'S02',
  /** Je přihlášená příliš blízká směna. */
  SHIFTS_TOO_CLOSE = 'S03',
  /** Moc směn / málo odpočinku. */
  INSUFFICIENT_BREAK = 'S04',

  /** Neplnoletý zaměstnanec. */
  EMPLOYEE_UNDERAGE = 'E01',
  /** Neplnoletý zaměstnanec na noční směně. */
  EMPLOYEE_UNDERAGE_IN_NIGHT_SHIFT = 'E02',
  /** Dítě, nezpůsobilé k práci. */
  EMPLOYEE_UNDER_15 = 'E03',
  /** Neplnoletý zaměstnanec nemá potvrzení o studiu. */
  EMPLOYEE_15_BUT_NOT_STUDYING = 'E04',
  /** Cizinec nemá informační kartu. */
  EMPLOYEE_FOREIGNER_BUT_NO_INFO_CARD = 'E05',
  /** Cizinec nemá vyplněný foreigner_origin. */
  EMPLOYEE_FOREIGNER_BUT_NO_FOREIGNER_ORIGIN = 'E06',

  /** Bude překročen (umělý) limit hrubé mzdy za měsíc. */
  SALARY_LIMIT_EXCEEDED = 'L01',
  /** Bude překročen (umělý) limit hrubé mzdy za měsíc, dosud ještě překročen není. */
  SALARY_LIMIT_EXCEEDED_NOW = 'L01n',
  /** Bude překročen limit odpracovaného času na smlouvu. */
  TIME_LIMIT_EXCEEDED = 'L02',

  /** Nemusí platit sociální a zdravotní pojištění. */
  EXEMPT_FROM_INSURANCE = 'I01',

  /** Uživatel nesplňuje speciální perk cachier. */
  MISSING_PERK_CACHIER = 'P01',
  /** Uživatel nesplňuje speciální perk billa_bakery. */
  MISSING_PERK_BILLA_BAKERY = 'P02',
  /** Uživatel nesplňuje speciální perk billa_service_desk. */
  MISSING_PERK_BILLA_SERVICE_DESK = 'P03',
}
